<template>
  <canvas
    :style="{
      width: '100%',
      'background-color': 'black',
      'background-image': `url(${require('@/assets/images/stars-foff.jpg')})`,
      'background-size': '100% auto',
      'background-position': 'center',
    }"
    ref="sphereMap"
  ></canvas>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { LightingEffect, AmbientLight } from "@deck.gl/core";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { OBJLoader } from "@loaders.gl/obj";

import { timeout } from "@/utils/misc";
import { getRandomElement } from "@/utils/random";
import { getCitiesDemoData, setCartoDefaultCredentials } from "@/deck/carto";
import { earthTileMaps } from "@/deck/maps";
import { getHexagonLayers } from "@/deck/h3";
import { createDeck } from "@/deck/Deck";
import { createGlobeMesh } from "@/components/layers/GlobeMesh";
import { createEarthTileLayer } from "@/components/layers/EarthTileLayer";
import { createCannesH3Layer } from "@/components/layers/CannesH3Layer";
import { REDRAWING_TIME, globeSettings } from "@/helpers/globe";
import {
  occasionColor,
  occasionTransitionDuration,
  occasionSettings,
  occasionPlateSettings,
  occasionPlatePoints,
} from "@/helpers/occasions";

import { createPlateLayer } from "@/components/layers/PlateLayer";
import { createCityPlateLayer } from "@/components/layers/CityPlateLayer";
import { createExperiencePlateLayer } from "@/components/layers/ExperiencePlateLayer";

let _animationTick = 0;

export default {
  name: "MainGlobe",
  data() {
    return {
      deckGl: null,
      occasions: [],
      showNow: {},
      occasionPlateStates: {},
      occasionPlateIds: [],
      occasionPoints: [],
      occasionStates: {},
      currentOccasion: {},
      idTimer: null,
    };
  },
  async mounted() {
    await this.getOccasion();
    await this.initialize();
  },

  beforeDestroy() {
    this.setMapLoaded(false);
    clearInterval(this.idTimer);
  },

  computed: {
    ...mapGetters("occasions", [
      "citiesData",
      "filteredOccasion",
      "occasionCategories",
      "occasionTitles",
      "filteredOccasionPoints",
    ]),
    ...mapGetters("mainGlobe", [
      "citiesOccasionsData",
      "isUserStopAnimation",
      "isShowPlateLayer",
      "opacityCitiesAll",
      "visibleCities",
      "viewState",
    ]),
  },

  methods: {
    ...mapActions("occasions", ["getOccasion"]),
    ...mapMutations("mainGlobe", [
      "setMapLoaded",
      "setIsUserStopAnimation",
      "setIsShowPlateLayer",
      "setOpacityCitiesAll",
      "setMapLoaded",
      "setVisibleCities",
      "setCitiesOccasionsData",
      "setViewState",
    ]),
    ...mapMutations("occasions", ["setFilter", "setSelectedOccasion"]),

    async onMapLoad() {
      // Add 1 second just to be sure the map was loaded.
      await timeout(1000);
      this.setMapLoaded(true);
    },

    async initialize() {
      setCartoDefaultCredentials();
      this.setCitiesOccasionsData(await getCitiesDemoData());

      // TODO: Another option: dark ocean, deployed to s3. Choose one of them later. hypHrSrR01Night deployed on heroku.
      // this.backgroundLayers = [createGlobeMesh(), createEarthTileLayer({ data: earthTileMaps.hypHrSrR02 })];
      this.backgroundLayers = [createGlobeMesh(), createEarthTileLayer({ data: earthTileMaps.hypHrSrR01Night })];
      this.hexagonLayers = getHexagonLayers();
      this.cannesH3Layer = await createCannesH3Layer();

      this.createOccasionPlateStates();
      this.createOccasionState();

      this.PlateLayer = await createPlateLayer(this);

      if (this.$route.name === "ExperienceCategory") {
        this.CityPlateLayer = await createExperiencePlateLayer(this);
      } else {
        this.CityPlateLayer = await createCityPlateLayer(this);
      }

      const additionalLayerFilter = ({ layer }) => {
        if (layer.id === "plate-layers" && !this.isShowPlateLayer) {
          return false;
        }

        if (layer.id === "experience-plate-layer" && this.$route.name !== "ExperienceCategory") {
          return false;
        }

        return !(layer.id === "city-plate-layer" && this.isShowPlateLayer);
      };

      const ambientLight = new AmbientLight({
        color: [255, 255, 255],
        intensity: 2.6,
      });

      const lightingEffect = new LightingEffect({ ambientLight });

      this.deckGl = createDeck(
        this,
        this.viewStateUpdate,
        {
          layers: [this.backgroundLayers, this.cannesH3Layer, ...this.hexagonLayers.layers],
          effects: [lightingEffect],
        },
        additionalLayerFilter
      );

      const animationRotate = () => {
        if (this.isUserStopAnimation) {
          return;
        }

        let long = this.viewState.longitude;

        if (long < -180) {
          this.viewState.longitude = 180;
        }

        this.setViewState({
          ...this.viewState,
          longitude: (this.viewState.longitude - globeSettings.rotateStep) % 360,
        });
      };

      this.idTimer = setInterval(() => {
        _animationTick++;
        animationRotate();
        this.viewStateUpdate(this.viewState);
        this.updateOccasions();
      }, REDRAWING_TIME);
    },

    viewStateUpdate(viewState) {
      const citiesAllLayer = new SimpleMeshLayer({
        id: "cities-all-layer",
        data: this.filteredOccasionPoints,
        mesh: require("@/assets/3d/pyramid.obj"),
        pickable: true,
        opacity: this.opacityCitiesAll,
        loaders: [OBJLoader],
        getColor: occasionColor,
        getPosition: (d) => [d.long, d.lat],
        getOrientation: () => [0, 90, 90],

        updateTriggers: {
          getScale: [_animationTick],
        },

        getScale: (d) => {
          if (!this.occasionStates?.[d.id]) {
            return [0, 0, 0];
          }
          const { startAnimationTime, opacity } = this.occasionStates?.[d.id];
          if (startAnimationTime > Date.now()) {
            return [...occasionSettings.initialColor, occasionSettings.startOpacity];
          }
          this.occasionStates[d.id].opacity = this.getOpacity(opacity);
          const size = opacity?.currentOpacity * 10;
          return [size, size, size];
        },

        onClick: (point) => {
          console.log("POINT:", point);

          this.setIsUserStopAnimation(true);
          const state = { ...viewState };
          this.setViewState(state);
          this.deckGl.setProps({
            viewState: state,
          });
        },
        transitions: {
          opacity: {
            duration: occasionTransitionDuration,
          },
        },
      });

      const citiesSelectedLayer = new SimpleMeshLayer({
        id: "cities-selected-layer",
        data: this.visibleCities,
        mesh: require("@/assets/3d/pyramid.obj"),
        opacity: !this.opacityCitiesAll,
        loaders: [OBJLoader],
        getPosition: (d) => [d.long, d.lat],
        getColor: occasionColor,
        getOrientation: () => [0, 90, 90],
        getScale: () => {
          const size = 255 * 20;

          return [size, size, size];
        },
      });

      this.deckGl?.setProps({
        viewState,
        width: "100%",
        height: "100%",

        layers: [
          this.backgroundLayers,
          this.cannesH3Layer,
          ...this.hexagonLayers.layers,
          citiesAllLayer,
          this.$route.name === "ExperienceCategory" ? null : this.PlateLayer(this, _animationTick),
          citiesSelectedLayer,
          this.CityPlateLayer(this, _animationTick),
        ],
      });
    },

    getOpacity({ currentOpacity, directionOpacity }) {
      const { stepOpacity, startOpacity, endOpacity } = occasionSettings;

      if (currentOpacity + stepOpacity >= endOpacity) {
        directionOpacity = 1;
      }

      if (currentOpacity - stepOpacity <= startOpacity) {
        directionOpacity = 0;
      }

      if (directionOpacity) {
        return {
          currentOpacity: currentOpacity - stepOpacity,
          directionOpacity: directionOpacity,
        };
      }

      return {
        currentOpacity: currentOpacity + stepOpacity,
        directionOpacity: directionOpacity,
      };
    },

    getOccasionOpacity({ currentOpacity, directionOpacity }) {
      const { stepOpacity } = occasionPlateSettings;

      if (directionOpacity) {
        return {
          currentOpacity: currentOpacity - stepOpacity < 0 ? 0 : currentOpacity - stepOpacity,
          directionOpacity: directionOpacity,
        };
      }

      return {
        currentOpacity: currentOpacity + stepOpacity > 255 ? 255 : currentOpacity + stepOpacity,
        directionOpacity: directionOpacity,
      };
    },

    updateOccasions() {
      if (Object.keys(this.showNow).length <= occasionPlateSettings.numberPoints) {
        const id = getRandomElement(this.occasionPlateIds);

        if (!this.showNow[id]) {
          this.showNow[id] = Date.now();
        }
      }
    },

    createOccasionPlateStates() {
      occasionPlatePoints.forEach((point) => {
        point.id = `${point.lat} ${point.long}`;

        this.occasionPlateIds.push(point.id);

        this.occasionPlateStates[point.id] = {
          opacity: {
            currentOpacity: occasionPlateSettings.startOpacity,
            directionOpacity: 0,
          },
          isShow: false,
        };
      });
    },

    createOccasionState() {
      this.filteredOccasionPoints.forEach((el) => {
        this.occasionStates[el.id] = {
          isShow: false,
          startAnimationTime: Date.now() + Math.ceil(Math.random() * occasionSettings.delay),
          opacity: {
            currentOpacity: occasionSettings.startOpacity,
            directionOpacity: 0,
          },
        };
      });
    },
  },
  watch: {
    filteredOccasionPoints() {
      this.createOccasionPlateStates();
      this.createOccasionState();
    },

    "$route.name": {
      handler: async function (val) {
        if (val === "ExperienceCategory") {
          this.CityPlateLayer = await createExperiencePlateLayer(this);
        } else {
          this.CityPlateLayer = await createCityPlateLayer(this);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss"></style>
