import { TextLayer, IconLayer } from "@deck.gl/layers";

import { addEllipsis } from "@/helpers/text";
import { occasionIconMapping } from "@/assets/icons/occasions-sprite";

export const createPlateIconLayer = ({ ctx, id, index = 0, offset = [0, 0], opacity = 1 }) => {
  return [
    new IconLayer({
      id: "icon" + id,
      data: ctx.props.data,
      getPosition: ctx.props.getPosition,
      pickable: true,
      opacity: opacity,
      iconAtlas: require("/src/assets/icons/occasions-sprite.svg"),
      iconMapping: occasionIconMapping,
      getIcon: (d) => d.occasions?.[index]?.occasion_name,
      billboard: true,
      sizeScale: 1.714,
      getSize: () => 16,
      getPixelOffset: offset,
      updateTriggers: {
        getPosition: ctx.props.updateTriggers.getPosition,
      },
    }),

    new TextLayer({
      id: "text" + id,
      data: ctx.props.data,
      getPosition: ctx.props.getPosition,
      getSize: () => 16,
      getText: (d) => addEllipsis(d.occasions?.[index]?.occasion_title?.toLowerCase()),
      getColor: ctx.props.getColor,
      opacity,
      sizeScale: 1,
      fontFamily: ctx.props.fontFamily,
      fontWeight: "400",
      getTextAnchor: ctx.props.getTextAnchor,
      getAlignmentBaseline: ctx.props.getAlignmentBaseline,
      getPixelOffset: [36, offset[1]],
      pickable: ctx,
      fontSettings: {
        sdf: ctx,
        radius: 20,
      },
      updateTriggers: {
        getPosition: ctx.props.updateTriggers.getPosition,
      },
    }),
  ];
};
