export default {
  namespaced: true,

  state: {
    isGlobeMap: true,
    isFirstLoading: true,
    isMapLoading: false,
    isInitialLoading: true,
    isShowMap: true,
  },

  mutations: {
    setGlobeMap(state, val) {
      state.isGlobeMap = val;
    },

    changeIsFirstState(state) {
      state.isFirstLoading = false;
    },

    changeMapState(state, val) {
      state.isMapLoading = val;
    },

    changeIsInitialLoading(state, val) {
      state.isInitialLoading = val;
    },

    handleMapLoadingState(state) {
      state.isMapLoading = state.isInitialLoading;
      state.isInitialLoading = false;
    },

    setIsShowMap(state, isShow) {
      state.isShowMap = isShow;
    },
  },

  getters: {
    isGlobeMap(state) {
      return state.isGlobeMap;
    },

    isFirstLoading(state) {
      return state.isFirstLoading;
    },

    isMapLoading(state) {
      return state.isMapLoading;
    },

    isShowMap(state) {
      return state.isShowMap;
    },
  },
};
